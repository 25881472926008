import { SCPB_LOGO } from "@/src/constants";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import Image from "next/image";
import React from "react";
import ContentContainer from "../../containers/ContentContainer";
import mortgageChoice from "../../../../public/images/mortgagechoice.png";
import Link from "next/link";
const Footer = () => {
  return (
    <div className="bg-scpb-dark-blue">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
        integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
        //@ts-ignore
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      />
      <ContentContainer>
        <div className="grid grid-rows-1 text-white w-[100%] footer-container">
          <div className="flex grid grid-rows-2 md:grid-cols-2 gap-4">
            <div
              style={{ position: "relative" }}
              className={
                "p-4 pb-8 md:pb-0 md:p-0 w-[250px] h-[49px] md:w-[350px] md:h-[69px] mx-auto md:mr-auto self-center"
              }
            >
              <Image
                fill
                src={SCPB_LOGO}
                alt="SCPB_LOGO"
                className="scpb-logo"
              />
            </div>
            <div className="w-full h-full flex  relative p-4">
              <div style={{ position: "relative" }} className="w-full h-full">
                <div
                  className="w-full h-[49px] max-w-[250px] md:max-w-[260px] md:h-full relative mx-auto self-center"
                  style={{ height: 70 }}
                >
                  <Image
                    fill
                    src={mortgageChoice}
                    alt="Mortgage Choice logo"
                    className="sponsor-logo"
                  />
                </div>
              </div>
            </div>
            <div
              className={"flex flex-row sm:w-full md:w-[50%] justify-between"}
            >
              <div className="flex flex-col text-xl">
                <Instagram style={{ fontSize: "2rem" }} />
                <Facebook style={{ fontSize: "2rem" }} />
                <Twitter style={{ fontSize: "2rem" }} />
              </div>
              <ul>
                <li>
                  <Link href="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link href="/about">About Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default Footer;
